@import '../../../../../../../../../../styles/base/color';

.skeleton-chart {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  &__card {
    width: 100%;
    height: 100%;
    padding: 16px 16px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    &--title, &--chart, &--container-users--each {
      background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
      border-radius: 3px;
      background-size: 200% 100%;
      animation: 1s shine linear infinite;
    }

    &--title {
      margin-top: 5px;
      height: 13px;
      width: 150px;
      margin-bottom: 18px;
    }

    &--container-users {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 6px;
      margin-bottom: 8px;
      padding: 0 16px;

      &--each {
        height: 9px;
        width: 90px;
      }
    }

    &--chart {
      width: 81%;
      height: 81%;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      &--hole {
        background-color: white;
        width: 49%;
        height: 49%;
        animation: none;
        z-index: 1;
        border-radius: 50%;
      }
    }

  }

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }

}