@import '../../../../../../../styles/base/color';

.info-detail {
  &__text {

    p {
      line-height: 24px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
    .two-items {
      grid-template-columns: 1fr 2fr;
    }
    
    .three-items {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &__imgs {
    height: 250px;
    padding-top: 10px;
  }
}