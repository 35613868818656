@import '../../styles/base/color';


.container {
    height: 100vh;
  
    & > div {
      border-radius: 20px;
      background-color: white;
      box-shadow: 0 10px 30px -10px $boxShadow;

      .logo { 
        width: 300px;
        height: auto;
      }
    }
  
}  