@mixin skeletonBasicsAnimation ($bgPrimary, $bgSecondary) {
  overflow: hidden;
  background: $bgPrimary;

  &::before {
      content: "";
      display: block;
      height: 100%;
      width: 100%;
      animation: loading 1s infinite;
      background: linear-gradient(to right, transparent,$bgSecondary,transparent);
  }

  @keyframes loading {
      0%{
          transform: translateX(-100%);
      }
      100%{
          transform: translateX(100%);
      }
  }

}