@import '../../../../../../styles/base/color';

.more-info-modal {
  width: 100%;
  &__container {
    width: 70%;
    height: 85%;
    background: #fff;
    transition: .6s;
    z-index: 100;
    position: absolute;
    top: -280px;
    border-radius: 8px;
    padding: 32px 0 32px 32px;

    @media screen and (max-width: 1500px) {
      width: 80%;
      height: 85%;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      height: 100%;
    }
    
    &--container {
      width: 100%;
      height: 100%;
      overflow: auto;
      padding-right: 32px;

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px; 
      }
      
      &::-webkit-scrollbar-track {
        background: transparent;
        height: 70%;
      }
        
      &::-webkit-scrollbar-thumb {
        background: $fontTertiary;
        border-radius: 4px;
      }

      &--close {
        position: absolute;
        right: 12px;
        top: 10px;
        font-size: 32px;
        color: $fontSecondary;
        cursor: pointer;
  
        &:hover {
          color: $bgHoverSecondary;
        } 
      }
  
      &--title {
        color: $primary;
        padding-bottom: 16px;
      }

    }

    &.show {
      top: 10%;
      left: 15%;

      @media screen and (max-width: 1500px) {
        top: 10%;
        left: 10%;
      }
      @media screen and (max-width: 768px) {
        top: 0;
        left: 0;
      }
    }

    

  }

  &__overlay {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, .5);
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  }

  &__container.show + &__overlay {
    display: block;
  }

}