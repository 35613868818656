@import '../../../../styles/base/color';


.cliente-row{
    display: flex;
    align-items: center;
    padding: 15px 20px;
    flex-grow: 1;
    margin-bottom: 16px;

    &.hover:hover {
        background-color: rgba(0, 0 , 0, .1);
    }

    &-info {
        width: 100%;
        margin-left: 20px;
        p { margin: 0; }
    }
}

.new-pieza {
    padding: 7.5px 10px;
    border-radius: 50%;
    cursor: pointer;
    background-color: $primary;
    margin-bottom: 16px;
}

.proveedor-card {
    padding: 10px 20px;
    flex-grow: 1;
    margin-bottom: 16px;


    &-info {
        width: 100%;
        p { margin: 0; }
    }
}

.pieza {
    p { margin-bottom: 0; }
}

.collapsable-content-img{
    border-top: 2px solid transparent;
  }

.presupuestos .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
    background-color: $warning !important;
    padding: 7px 15px;
    
    small { color: #FFF !important; font-weight: 700;}
  }