.btn-custom {
  white-space: nowrap;
  color: #215731 !important;
  background: #e9eeea;
  border: 1px solid #215731;
  &:hover {
    background: #1d4a2b;
    border: 1px solid #1d4a2b;
    color: white !important;
  }
}

.btn-divider {
  position: relative;
  ::before {
    position: absolute;
    content: "";
    display: block;
    height: 60%;
    width: 1px;
    left: 0;
    top: 20%;
    background: white;
  }
}
