.scrapping-by-ref-form {
    .market-places-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        input {
            margin-right: 4px;
        }
    }
}

.part-searcher-results-list {
    .results-list-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        .part-card-ul {
            min-height: 165px;
        }
    }

    .part-details-image {
        .image-wrapper {
            overflow: hidden;

            img {
                width: 100% !important;
            }
        }
    }

    .empty-div {
        min-height: 140px;
    }
}

.results-list-filter {
    background-color: #F5F5F7;
    font-size: 18px !important;
    font-weight: bolder !important;
}