@import '../../../../../../styles/base/color';

.lightbox {
    position: fixed;
    z-index: 9999;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(73, 73, 73, 0.9),#000 1810%);

    &-container {
        opacity: 1;
        transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        right: 0;
        bottom: 0;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-tap-highlight-color: transparent;
        z-index: -1;
        width: 100%;
        height: 100%;
    }

    &-header {
        width: 100%;
        height: 45px;
        position: absolute;
        top: 0;
        left: 0;

        .toolbar {
            height: 100%;
            position: absolute;
            z-index: 3;
            right: 5px;
            top: 0;
            display: flex;
            background: rgba(35,35,35,.65);
            padding: 15px;
            font-size: 1.4rem;
            column-gap: 10px;
        }
    }

    .swiper {
        margin-left: auto;
        margin-right: auto;
    }
      
    .swiper-slide {
        background-size: cover;
        background-position: center;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
      
    .swiperPrincipal {
        height: 80%;
        width: 100%;

        .swiper-slide {
            img { 
                height: 80vh !important; 
                object-fit: contain;
            }
        }

        .swiper-button-prev::after, .swiper-button-next::after {
            font-size: 1.5rem !important;
        }
    }
      
    .swiperThumbs {
        height: 20%;
        box-sizing: border-box;
        padding: 10px 0;

        .swiper-slide {
            width: 20% !important;
            height: 100%;
            opacity: 0.4;
        }

        .swiper-slide-thumb-active { opacity: 1; }
    }
}