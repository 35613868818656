@import '../../../../../../../../styles/base/color';

.avisos-fecha {
    border-bottom: 1px solid #222;
    padding: 20px 0;

    .aviso {
        padding: 10px 0;
        border-bottom: 1px solid #d8dbdf;

        &:last-child { border-bottom: none !important; }
    }
}