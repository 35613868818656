@import '../../../../styles/base/color';

.tecdoc {
  width: 100%;
  height: 100%;
  max-width: 1450px;
  padding: 0 1.5rem 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto;

  &__search-container {
    width: 100% !important;
    padding-top: 16px;
    display: flex;
    
    &--search {
      width: 300px !important;
    }
  }

  &__table-container {
    padding: 28px 0 12px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
    
    &--table {
      width: 100%;
      height: 100%;
      -webkit-border-radius: 3px; 
      -moz-border-radius: 3px; 
      border-radius: 6px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px; 
      }
      
      &::-webkit-scrollbar-track {
        background: transparent;
      }
        
      &::-webkit-scrollbar-thumb {
        background: $primary;
        border-radius: 4px;
      }

      table {
        height: 100%;
        border-collapse: collapse; 
        text-align: left; 
        width: 100%;
        overflow: auto;
        border: 1px solid #2157320b;

        thead {
          th {
            background-color:$primary; 
            color:#FFFFFF;
            font-weight: 500;
            position: sticky;
            top: -1px; 
            z-index: 1;
          }
        }

        tbody {
          tr { 
            color: $fontSecondary;
            &:nth-child(even) {
              background-color: #2157320b;
            }

            button {
              background-color: $primary;
              color: $white;
              font-weight: 300;
              padding: 3px 10px;
              border-radius: 5px;
              font-size: 14px;

              &:hover {
                background-color: $bgHoverPrimary;
              }
            }
          }
        }

        td, th {
          padding: 12px;
        }

      }
    }
  }
}