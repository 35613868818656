.part-card {
    width: 100%;
    padding: 15px;
    cursor: pointer;

    .image-wrapper {
        max-height: 255px;
        min-height: 255px;
        border-radius: 10px;
        object-fit: cover;

        &__img-container {
            max-height: 255px;
            overflow: hidden;
        }
    }

    &__info {
        min-height: 208px;
    }

    .select-button {
        background-color: white;
        border-width: 1px !important;
        border-radius: 10px;
    }
}

.grey-part-card {
    background-color: #F5F5F7;
}

.green-part-card {
    background-color: #D9EFDC;
}