

.file-grid {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;

    .item {
        //max-height: 250px;
        padding: 10px;

        img, video {
            max-width: 100%;
            height: 100%;
            object-fit: cover;
        }

        object.pdf {
            max-width: 100%;
            height: 100%;
        }

        object.txt {
            max-width: 100%;
            height: 100%;
        }

        .preview {
            max-height: 100%;
            overflow-y: auto;

            &-text {
                font-size: 0.875rem;
            }
        }
    }
}