@import '../base/color';

.MuiTooltip-popper {
    & > * {
        border: 1px solid $primary !important; 
        background-color: $skeletonSecondary !important;
        box-shadow: 0px 0px 20px rgba(171, 171, 171, 0.25);
    }
    

    small {
        font-size: 0.875rem !important;
        text-transform: uppercase;
    }

    ul {
        margin-left: 0;
        padding-left: 15px;
        list-style: square !important;

        li { list-style: square !important; }
    }

    table {
        tr {
            td { padding: 8px 12px; }
        }
    }
}