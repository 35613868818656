@import '../../../../styles/base/color';


.incidencia {
    &-card {
        padding: 20px;
        border-radius: 20px;
        box-shadow: 0 10px 30px -10px $boxShadow;
    
        p { 
            margin: 0; 
            font-size: 1rem;
            text-transform: uppercase;
        }

        .info p { font-size: 0.875rem; }
    }
}

.avisos {
    .aviso {
        .info {
            p { 
                color: $light-gray;
                font-size: 1rem; 
                font-weight: 700;
            }
        }
    }

    .legend-day { 
        h5 { 
            font-size: 1.25rem; 
            margin: 0;
            font-weight: 700;

            small { 
                color: $light-gray;
                font-weight: 700;
            }
        }
        
    }
}

.chat-container {
    padding: 25px;
    border-radius: 20px;
    box-shadow: 0 10px 30px -10px $boxShadow;
    height: 100%;

    .chat-wrapper {
        max-height: 350px;
        overflow-y: auto;

        h1 { font-size: 1rem; }
    }
}

.chat {
    margin-bottom: 20px;
    position: relative;

    &.justify-content-end {
        .chat-message {
            background-color: #f5f5f5;
            text-align: right;
        }

        .chat-comment { text-align: right; }
    
        .chat-info {
            position: absolute;
            right: 5px !important;
            left: revert;
        }
    }

    .chat-message {
        padding: 10px;
        border-radius: 16px;
        background-color: #94d3a2;
        width: auto;
        font-size: 0.688rem;
    }

    &-comment { width: 100%; }

    &-info {
        position: absolute;
        left: 5px;
        bottom: -10px;

        span { font-size: 0.625rem; }
    }
}
